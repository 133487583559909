import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import bindPermission from './bindPermission';
export default {
  name: "Org",
  mixins: [mixins],
  data() {
    return {
      currentOrg: null,
      bindPermissionDialog: false,
      columns: [{
        label: '机构编码',
        prop: 'orgCode'
      }, {
        label: '机构名称',
        prop: 'orgName'
      }],
      topButtons: [{
        name: '新增',
        code: 'add',
        type: 'primary',
        click: this.orgAdd,
        permissions: ['org_add']
      }],
      linkButtons: [{
        name: '修改',
        code: 'org_edit',
        click: this.orgEdit,
        permissions: ['org_edit']
      }, {
        name: '分配权限',
        code: 'org_bind_permission',
        click: this.orgBindPermission,
        permissions: ['org_bind_permission']
      }]
    };
  },
  components: {
    bindPermission
  },
  methods: {
    orgAdd() {
      this.$router.push('/orgAdd');
    },
    orgEdit(row) {
      this.$router.push({
        path: '/orgAdd',
        query: {
          orgId: row.orgId
        }
      });
    },
    orgBindPermission(row) {
      this.currentOrg = row;
      this.bindPermissionDialog = true;
    },
    doBindPermission(orgId, permissionIds) {
      const loading = ElLoading.service();
      this.$api.uac.org.bindPermission(orgId, permissionIds).then(res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          this.bindPermissionDialog = false;
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};